<template>
  <div>
    <CRow>
      <CCol>
        <CButton class="float-right" color="success" @click="() => $router.push('/course/create')">
          Nuevo curso
        </CButton>
      </CCol>
    </CRow>
    <CRow class="mt-4">
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            Listado de cursos
          </CCardHeader>
          <CCardBody>
            <table class="table">
              <tr>
                <th>Título</th>
                <th>Imagen</th>
                <th>Descripción</th>
                <th>Activo</th>
                <th>Creado en</th>
                <th>Acciones</th>
              </tr>

              <tr v-for="course in courses" :key="course.id">
                <td>{{ course.title }}</td>
                <td>
                  <img v-bind:src="course.thumbnail" alt="" width="100px">
                </td>
                <td>{{ course.description }}</td>
                <td>
                  <CIcon v-if="course.active" name="cil-check" />
                </td>
                <td>{{ course.created_at | dateFormat }}</td>
                <td>
                  <CButton
                    @click="() => $router.push('/course/' + course.id + '/edit')"
                  >
                    <CIcon name="cil-pencil" />
                  </CButton>
                </td>
              </tr>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import { freeSet } from '@coreui/icons'
import moment from "moment";
import store from '@/store'

export default {
  icons: { freeSet },
  name: 'Course',
  data: function () {
    return {
        courses: [],
      show: true,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.'
    }
  },
  filters: {
    dateFormat: function (value) {
      return value ? moment(value).format("YYYY-MM-DD HH:mm") : "";
    },
  },
  mounted() {  
    const token = store.state.user.token;
      axios
        .get(process.env.VUE_APP_BACKEND_URL+'/api/course?active=true', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            this.courses = response.data.data;
        })
  }
}
</script>
